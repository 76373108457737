<template>
  <div class="managingPeople hp100 bbox" v-loading="loading">
    <!-- 卡片视图 -->
    <!-- <el-card> -->
      <!-- 搜索-->
      <el-row :gutter="20" type="flex" justify="space-between">
        <el-col>
          <el-button
            plain
            class="add-column-btn bbox"
            type="primary"
            @click="() => {this.dialogVisible = true}"
          >
            <i class="el-icon-plus fw900 f16"></i> 新增</el-button
          >
        </el-col>
      </el-row>
      <!-- 用户列表区域 -->
      <el-table
        :data="tableData"
        border
        fit
        :height="height"
        highlight-current-row
        style="width: 100%"
      >
        <el-table-column label="序号" type="index" align="center" width="50">
        </el-table-column>
        <el-table-column label="类型名称" align="center" prop="id">
          <template slot-scope="scope">
            <span>{{ scope.row.fenceType }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" prop="">
          <template slot-scope="scope">
            <span v-if="scope.row.createDt">{{
              scope.row.createDt | dataFormat
            }}</span>
            <span v-else>---</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
        >
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="编辑"
              placement="bottom"
            >
              <i
                class="el-icon-edit textblue f16"
                @click="edit(scope.row)"
              ></i>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="bottom"
            >
              <i
                class="el-icon-delete textblue f16 ml20"
                @click="del(scope.row.id)"
              ></i>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <div class="footerBox">
        <!-- 分页区域 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.page"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="queryInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    <!-- </el-card> -->

    <!-- 添加类型 -->
    <el-dialog
        :title="isEdit == true ? '编辑类型':'新增类型'"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
          <el-row>
            <el-form>
                 <el-col :span="24" style="margin-top:20px;">
                    <el-form-item label="类型名称：" label-width="90px">
                        <el-input v-model="editItem.fenceType"></el-input>
                    </el-form-item>
                 </el-col>
            </el-form>
          </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="saveType">保 存</el-button>
        </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      height: window.innerHeight - 256, //表格高度
      tableData: [],
      loading: true,
      isEdit:false,
      editItem:{},
      // 获取用户列表查询参数对象
      queryInfo: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 10,
        keyvalue: "",
        //查询条件
        condition: {

        }
      },
      total: 0,
      //
      dialogVisible: false,
    };
  },
  created() {
    this.getListData();
  },
  mounted() {
    this.loading = false;
  },
  destroyed() {
  },
  methods: {
    saveType(){
       let data = this.editItem
       this.$http.post('/fenceType/save',data).then(res => {
         if(res.data.code == 200) {
            this.$notify.success({
              title: "提示",
              message: "保存成功",
              showClose: true
            });
            this.getListData();
            this.dialogVisible = false
          } else {
            this.$notify.error({
              title: "提示",
              message: res.data.message,
              showClose: true
            });
          }
       })
    },
    edit(row){
       this.editItem = JSON.parse(JSON.stringify(row))
       this.dialogVisible = false
    },
    handleClose(){
      this.dialogVisible = false
      this.editItem = {}
    },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getListData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.currPage = newSize;
      this.getListData();
    },
    // 删除用户
    async del(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => err);
      if (confirmResult === "confirm") {
        var that = this;
        that.$http
          .post("/fenceType/delete", { id: id })
          .then(function(response) {
            that.$notify.success({
              title: "提示",
              message: "删除成功",
              showClose: true
            });
            that.getListData();
          });
      }
    },
    getListData() {
      var that = this;
        that.$http
          .post("fenceType/list", {
            currPage: that.queryInfo.currPage,
            pageSize: that.queryInfo.pageSize,
            condition: {}
          })
          .then(function(response) {
            if (response.data.code == 200) {
              that.total = response.data.data.count;
              that.tableData = response.data.data.data;
            }
          });
    },
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - 256;
    }
  }
};
</script>

<style lang="less" scoped>
.textblue {
  color: #008eff;
}
.managingPeople {
  .el-card {
    height: calc(100vh - 114px);
    position: relative;
    .el-select > .el-input {
      width: 200px;
    }
    // .header-search-box .header-search-input {
    //     width: 325px;
    // }
    input::placeholder {
      font-size: 12px;
    }
    .handle-btn .el-button.add-column-btn {
      width: 100%;
      font-size: 13px;
      background-color: transparent;
      color: #008eff;
      z-index: 102;
    }
    .footerBox {
      position: absolute;
      width: 100%;
      height: 78px;
      left: 20px;
      right: 20px;
      bottom: 0;
    }
  }
}
/deep/ .el-dialog__body{
    padding: 20px;
}
</style>
